import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, AlertIcon, Box, Button, Input, InputGroup, InputLeftAddon, Stack, StatLabel } from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Typography } from 'antd';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const firebaseConfig = {
  apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
  authDomain: 'missis-laser.firebaseapp.com',
  projectId: 'missis-laser',
  storageBucket: 'missis-laser.appspot.com',
  messagingSenderId: '514174044584',
  appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
  measurementId: 'G-QTNN569ZLV',
};

const utm_campaign = '(Все тело 2990)';
const utm_medium = '(Все тело 2990)';

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const history = useHistory();

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  React.useEffect(() => {
    logEvent(analytics, window.location.hostname);
  }, [analytics]);

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        utm_medium,
        phone,
        name: username,
        utm_source: window.location.hostname,
        utm_campaign,
      }).then(() => {
        logEvent(analytics, `${window.location.hostname}_APPOINTMENT`);
        history.push(Routes.Success);
      });
    } catch (error) {
      history.push(Routes.Error);
      console.log(JSON.stringify(error));
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
          Оставьте заявку и получите обещанный купон на лазерную эпиляцию!
          <br />
          Студия Миссис Лазер
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center', fontWeight: '700' }}>
              Ваши контактные данные
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Ваше имя'
                value={username}
                onChange={e => setUserName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Номер телефона'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
          </Stack>
          <Stack spacing='1rem' style={{ marginTop: 0 }}>
            {error && (
              <Alert status='error'>
                <AlertIcon />
                К сожалению, данная акция только на первую процедуру! <br />
                Но у нас есть классные акции и для наших постоянных клиентов. <br />
                Пожалуйста, напишите нам в директ Instagram ваш телефон и наш менеджер вам все расскажет ;)
              </Alert>
            )}
          </Stack>
          <Button
            onClick={onSubmit}
            mt='24px'
            w='100%'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
            mb='24px'
            style={{ marginBottom: '24px' }}
          >
            Получить подарок
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
